<!-- 学员信息 -->
<template>
    <div class="RegisterDetails">
        <!-- 上面部分 -->
        <div class="top">
            <span style="font-size: 16px; font-weight: bold;">学员信息</span>
            <el-button class="el-btn" size="small" @click="goBack">
                返回
            </el-button>
        </div>
        <div class="">
            <button class="btn" type="button" v-for="(item,index) in tabBtn" :key="index"
             @click="toTab(index)" :class="tab==index?'active':''">
                {{item}}
            </button>
        </div>
        <!-- <el-divider></el-divider> -->
        <!-- 单据说明 -->
        <div class="" v-if="tab==0" :key="0">
            <el-form :model="rulform" inline class="form_2" label-width="180px">
                <el-form-item label="学员姓名">
                    <el-input :value="rulform.name" disabled></el-input>
                </el-form-item>
                <el-form-item label="联系电话">
                    <el-input :value="rulform.telephone" disabled></el-input>
                </el-form-item>
                <el-form-item label="身份证号">
                    <el-input :value="rulform.IDcard" disabled></el-input>
                </el-form-item>
                <el-form-item label="科目">
                    <el-input :value="rulform.subject" disabled></el-input>
                </el-form-item>
                <el-form-item label="报考等级">
                    <el-input :value="rulform.grade" disabled></el-input>
                </el-form-item>
                <el-form-item label="提供机构">
                    <el-input :value="rulform.provide" disabled></el-input>
                </el-form-item>
            </el-form>   
        </div>
        <!-- 安排表 -->
        <div class="" v-if="tab==1" :key="1">
            <el-table :data="planData" border stripe style="width:97.9%;flex: 1;" height="650" >
                <!-- 复选按钮 -->
                <!-- <el-table-column type="selection" align="center" width="50">
                </el-table-column> -->
                <el-table-column prop="" label="序号" align="center" width="80">
                </el-table-column>
                <el-table-column prop="" label="安排状态" align="center" width="100">
                </el-table-column>
                <el-table-column prop="" label="安排单号" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" label="学员姓名" align="center" width="100">
                </el-table-column>
               <el-table-column prop="" label="拼音名" align="center" width="100">
               </el-table-column>
               <el-table-column prop="" label="性别" align="center" width="80">
               </el-table-column>
               <el-table-column prop="" label="出生日期" align="center" width="150">
               </el-table-column>
               <el-table-column prop="" label="国籍" align="center" width="100">
               </el-table-column>
               <el-table-column prop="" label="民族" align="center" width="100">
               </el-table-column>
               <el-table-column prop="" label="学科" align="center" width="120">
               </el-table-column>
               <el-table-column prop="" label="等级" align="center" width="100">
               </el-table-column>
               <el-table-column prop="" label="考场编号" align="center" width="120">
               </el-table-column>
               <el-table-column prop="" label="考场摘要" align="center" width="200">
               </el-table-column>
               <el-table-column prop="" label="场地" align="center" width="120">
               </el-table-column>
               <el-table-column prop="" label="考试日期" align="center" width="120">
               </el-table-column>
               <el-table-column prop="" label="考试时间" align="center" width="200">
               </el-table-column>
               <el-table-column prop="" label="机构名称" align="center" width="150">
               </el-table-column>
               <el-table-column prop="" label="说明" align="center" width="200">
               </el-table-column>
            </el-table>
        </div>
        <!-- 考试历史 -->
        <div class="" v-if="tab==2" :key="2">
            <el-table :data="historyData" border stripe style="width:97.9%;flex: 1;" height="650" >
                <!-- 复选按钮 -->
               <!-- <el-table-column type="selection" align="center" width="50">
                </el-table-column> -->
                <el-table-column type="index" label="序号" align="center" width="80">
                </el-table-column>
                <el-table-column prop="name" label="学员姓名" align="center" width="100">
                </el-table-column>
                <el-table-column prop="org_name" label="提供机构" align="center" width="200">
                </el-table-column>
                <el-table-column prop="course" label="考试科目" align="center" width="120">
                </el-table-column>
                <el-table-column prop="exam_level" label="等级" align="center" width="100">
                </el-table-column>
                <el-table-column prop="remark" label="考试备注" align="center" width="120">
                </el-table-column>
                <el-table-column prop="text_score" label="成绩" align="center" width="80">
                </el-table-column>
                <el-table-column prop="exam_result" label="成绩等级" align="center" width="100">
                    <template slot-scope="scope">
                        <span v-if="scope.row.exam_result==1">未录入</span>
                        <span v-else-if="scope.row.exam_result==2">
                            {{ scope.row.exam_level }}
                        </span>
                        <span v-else-if="scope.row.exam_result==3"></span>
                    </template>
                </el-table-column>
                <el-table-column prop="exam2_date" label="考试日期" align="center" width="200">
                </el-table-column>
            </el-table>
        </div>
        <!--分页 -->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40, 50, 100]" :page-size="10" background
            layout="total, sizes, prev, pager, next, jumper" :total="total" v-if="tab!==0">
        </el-pagination>
    </div>
</template>

<script>
    export default{
        data() {
            return{
               tabBtn:["单据说明","安排表","考试历史"], 
               tab:0,//代表下标
               rulform:{
                   name:"",//学员姓名
                   telephone:"",//联系电话
                   IDcard:"",//身份证号
                   subject:"",//科目
                   grade:"",//报考等级
                   provide:""//提供机构
               },
               planData:[],//安排表数据
               historyData:[],//考试历史数据
               currentPage: 1,//页数
               currentLimit: 10,//条数
               total: 0,//总数
            }
        },
        created() {
            this.getList()
        },
        methods:{
            goBack() { //返回
                this.$router.go(-1);
            },
            toTab(index){
                this.tab=index
                this.getList()
            },
            getList(){
               if(this.tab==0){//单据说明
                   this.$request({url:'/api/examenroll/description',method:'POST',data:{
                       id:this.$route.query.id
                   }}).then(res=>{
                       console.log(res)
                       let data = res.data
                       if(res.code==1){
                          this.rulform.name=data.name//学员姓名
                          this.rulform.telephone=data.phone//联系电话
                          this.rulform.IDcard=data.idcard//身份证号
                          this.rulform.subject=data.course //科目
                          this.rulform.grade=data.exam_level //报考等级
                          this.rulform.provide=data.org_name //提供机构
                       }
                   })
               }else if(this.tab==1){//安排表
                   this.$request({url:'',method:'POST',data:{
                       id:this.$route.query.id,
                       page:this.currentPage,
                       limit:this.currentLimit
                   }}).then(res=>{
                       // console.log(res)
                       // if(res.code==1){

                       // }
                   })
               }else if(this.tab==2){//考试历史
                   this.$request({url:'/api/examenroll/history',method:'POST',data:{
                       idcard:this.rulform.IDcard,
                       page:this.currentPage,
                       limit:this.currentLimit
                   }}).then(res=>{
                       console.log(res)
                       if(res.code==1){
                            this.historyData=res.data.list
                            this.total=res.data.count
                       }
                   })
               }  
            },
            handleSizeChange(val) {
                // console.log(`每页 ${val} 条`);
                // this.currentLimit=val
                // this.getList()
            },
            handleCurrentChange(val) {
                // console.log(`当前页: ${val}`);
                // this.currentPage=val
                // this.getList()
            },
        }
    }
</script>

<style scoped="scoped">
    .RegisterDetails{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    /* 上面部分 */
    .top {
        padding-top: 25px;
        margin: 0px 24px 39px 28px;
        display: flex;
        justify-content: space-between;
    }
    
    .el-btn {
        background: #18BC9C;
        padding-left: 25px;
        background-position: 5px 7px;
        display: inline-block;
        background-image: url(../../assets/back.png);
        background-repeat: no-repeat;
        color: #FFFFFF;
    }
    /* tab按钮 */
    .btn{
       display: inline-block;
       border: 1px solid #999999;
       background-color: #FFFFFF;
       padding: 5px 15px;
       margin:0px 22px ;
       font-size: 14px;
       border-radius: 3px;
       cursor: pointer;
    }
    /* 选中样式 */
    .active{
        background: #26716C;
        color: #fff;
    }
    /* 表单 */
    .form_2{
       display: flex;
       justify-content: start;
       flex-wrap: wrap;
       margin-top: 40px;
    }
    .form_2::v-deep .el-input__inner {
        width: 300px;
        height: 32px;
        border:1px solid #ccc;
        background-color: #ffff;
        color: #000000;
    }
    .form_2::v-deep .el-form-item__label {
        color: #000000;
    }
    /* 表格 */
    .el-table {
        margin: 20px;
    }
    
    ::v-deep .el-table__cell {
        color: #000;
        padding: 0;
        height: 35px;
    }
    /* 分页 */
    .el-pagination {
        margin: 20px;
        margin-top: 0px;
    }
    
    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #2C3E50 !important;
    }
</style>
